<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Relatórios - Contratos de alunos</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Relatórios
                  <span>Contratos</span> de alunos
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <section class="fd-mode-opcoes">
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Lista de contratos
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 table-responsive">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Matrícula</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ativa</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">Aluno</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">Responsável</small>
                                  </th>
                                  <th>
                                    <small class="font-weight-bold">Valor pago</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastContratosAlunos.loading">
                            <tr>
                              <td
                                colspan="6"
                                class="text-center"
                              >
                              <b-icon icon="gear-fill" animation="spin"/> Carregando pessoas 
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else-if="fastContratosAlunos.paginacao.length">
                            <tr
                              v-for="(e, index) in fastContratosAlunos.paginacao"
                              :key="index"
                            >
                              <td class="align-middle text-center">
                                <small>#{{ e.id_matricula }}</small>                                  
                              </td>
                              <td class="align-middle text-center">
                                <small v-if="e.MatriculaAtiva"><span class="text-success">Sim</span></small>                                  
                                <small v-else><span class="text-danger">Não</span></small>                                  
                              </td>
                              <td class="align-middle">
                                <small>{{e.nome_aluno}}</small>
                              </td>
                              <td class="align-middle">
                                <small>{{e.nome_responsavel}}</small>
                              </td>
                              <td class="align-middle">
                                <small>R$ {{formataPreco(e.ValorPago)}}</small>
                              </td>
                              <td class="align-middle">
                                
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                colspan="6"
                                class="text-center"
                              >
                                Nenhum contrato encontrado
                              </td>
                            </tr>
                          </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="20"
                          :items="fastContratosAlunos.filtro"
                          @changePage="fastContratosAlunos.paginacao = $event"
                        />
                      </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCancelarNota"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Tem certeza que deseja cancelar a nota?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCancelarNota')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoTesourariaRelatoriosContratoAlunos",
  components: {
    Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastContratosAlunos: {
        loading: true,
        total: [],
        filtro: [],
        paginacao: []
      }
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (
            this.$store.state.fastPlataformaModulos.modulo_secretaria
          ) {            
            this.$store.state.fastCarregando = false;
            this.getFastHoraServidor()
            .then(() => {
              this.getContratosAlunos(this.$route.params.id_plataforma)
            })

          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getContratosAlunos(id_plataforma){      
      this.fastContratosAlunos.loading = true
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_aluno_posicao", "ids_plataforma=" + id_plataforma)
      .then(res => {
        console.log("getContratosAlunos", res)
        if (res.length) {
          this.fastContratosAlunos.total = res
          this.fastContratosAlunos.filtro = res
        }
        else {
          this.fastContratosAlunos.total = []
          this.fastContratosAlunos.filtro = []
        }

        this.fastContratosAlunos.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastContratosAlunos.loading = false
      })
    },
  },
};
</script>

<style scope>

</style>
